// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //API_BASE_URL: 'https://localhost:44397/api/',
  // API_BASE_URL:
  // 'http://leadspot-test-env.eba-tnm8b5xd.us-east-2.elasticbeanstalk.com/api/',
  API_BASE_URL: 'https://app.leadspotting.com/api/',
  API_BASE_URL2: 'https://api.leadspotting.com/LeadsPortal.jsp?',
  BASE_URL: 'https://api.leadspotting.com/LeadSpottingApi.jsp?',
  BASE_URL1: 'https://api.leadspotting.com/Leadspot.jsp?',
  BASE_URL2:
    'http://test-leadspottingapi-env.eba-mreepfyi.us-east-2.elasticbeanstalk.com/Leadspot.jsp?',
  GOOGLE_VISION: 'https://vision.googleapis.com/v1/images:annotate',
  GOOGLE_VISION_KEY: 'AIzaSyC9IlHN-6HcPtzYP0O1kxzetWcY3iROZWE',
  IS_WHITE_LABEL: false,
  SEARCH: true,
  AI_MESSAGING: true,
  CONTACTS: true,
  OPTIMIZATION: true,
  AFFILIATES: true,
  DASHBOARD: true

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
