import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  PartnerClients,
  CompanyContact,
  ClientUser,
  Contact,
  ClientPlan,
  Project,
  User,
  CustomerMap,
} from '../models';
import { PlanModel } from '../models/PlanModel';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
const md5 = require('md5');

@Injectable({
  providedIn: 'root',
})
export class BackEndService {
  constructor(private http: HttpClient) {}

  searchForCompanies(model) {
    const params = new HttpParams()
      .set('country', model.country ? model.country : '')
      .set('region', model.region ? model.region : '')
      .set('industry', model.industry ? model.industry : '')
      .set('size', model.size ? model.size : '')
      .set('text', model.text ? model.text : '')
      .set('office', model.office ? model.office : '')
      .set('product', model.product ? model.product : '')
      .set('description', model.description ? model.description : '')
      .set('clientId', localStorage.getItem('LeadSpotClientId'));
    return this.http.get<any>(
      environment.API_BASE_URL + 'Search/CompanySearch',
      { params }
    );
  }

  CreateContactEnrichmentTask(model) {
    return this.http.post<number>(
      environment.API_BASE_URL + 'Lead/CreateContactEnrichmentTask',
      model
    );
  }
  buyList(model) {
    const params = new HttpParams()
      .set('industryId', model.industryId)
      .set('orderId', model.orderId)
      .set('count', model.count)
      .set('locationId', model.locationId);
    return this.http.get(environment.API_BASE_URL + 'Lead/buyList', {
      params,
    });
  }
  companySearchCounts(model) {
    const params = new HttpParams()
      .set('country', model.country ? model.country : '')
      .set('region', model.region ? model.region : '')
      .set('industry', model.industry ? model.industry : '')
      .set('size', model.size ? model.size : '')
      .set('limit', model.limit)
      .set('text', model.text ? model.text : '')
      .set('office', model.office ? model.office : '')
      .set('product', model.product ? model.product : '')
      .set('offset', model.offset)
      .set('description', model.description ? model.description : '')
      .set('clientId', localStorage.getItem('LeadSpotClientId'));
    return this.http.get<any>(
      environment.API_BASE_URL + 'Search/CompanySearchCounts',
      { params }
    );
  }
  getCountriesCodes() {
    return this.http.get('/assets/codes.txt', {
      responseType: 'text' as 'json',
    });
  }

  searchForContact(model) {
    const type = model.type ? model.type : '';
    const value = model.value ? model.value : '';
    const params = new HttpParams()
      .set('clientId', model.clientId)
      .set('type', type)
      .set('value', value);
    return this.http.get<any>(
      environment.API_BASE_URL + 'Search/ContactSearch',
      { params }
    );
  }
  companyEnirchment(website) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('website', website);
    return this.http.get<any>(
      environment.API_BASE_URL + 'Search/CompanyEnirchment',
      { params }
    );
  }
  getUserClaims() {
    return this.http.get<any>(environment.API_BASE_URL + 'User');
  }

  register(form, id = '', source) {
    // .set("Phone",form.countryCode.toString().trim()+'-'+form.phone.toString().trim())
    const params = new HttpParams()
      .set('Name', form.name)
      .set('Email', form.email)
      .set('Pwd', form.password)
      .set(
        'Phone',
        form.countryCode
          ? form.countryCode.toString().trim() +
              '-' +
              form.phone.toString().trim()
          : ''
      )
      .set('isGo', '0')
      .set('InvitationToken', form.InvitationToken ? form.InvitationToken : '')
      .set('PartnerId', id)
      .set('RedirectURL', form?.redirectURL)
      .set('Source', source);
    return this.http.get<string>(environment.API_BASE_URL + 'User/Register', {
      params,
    });
  }

  googleSignIn(form) {
    const params = new HttpParams()
      .set('Name', form.name)
      .set('Email', form.email)
      .set('Pwd', form.password)
      .set('Phone', form.phone)
      .set('isGo', '1')
      .set('InvitationToken', form.InvitationToken ? form.InvitationToken : '')
      .set('PartnerId', '');
    return this.http.get<any>(environment.API_BASE_URL + 'User/GoogleSignIn', {
      params,
    });
  }
  verifyUser(token, isPin = 0) {
    const params = new HttpParams()
      .set('token', token)
      .set('isPin', isPin.toString());
    return this.http.get<string>(environment.API_BASE_URL + 'User/Verify', {
      params,
    });
  }
  createProduct(name) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('name', name);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Crm/createProduct',
      { params }
    );
  }
  createTag(name) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('name', name);
    return this.http.get<number>(environment.API_BASE_URL + 'Crm/createTag', {
      params,
    });
  }

  createCampaign(name) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('name', name);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Crm/CreateCampaign',
      { params }
    );
  }

  createStatus(name) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('name', name);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Crm/createStatus',
      { params }
    );
  }

  createMessagingTemplate(name, template) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('name', name)
      .set('template', template);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Crm/CreateMessagingTemplate',
      { params }
    );
  }

  getCrmConfig(selectedUserId) {
    let params;
    if (selectedUserId == 0) {
      params = new HttpParams().set(
        'clientId',
        localStorage.getItem('LeadSpotClientId')
      );
    } else {
      params = new HttpParams().set('clientId', selectedUserId);
    }
    return this.http.get<any>(environment.API_BASE_URL + 'Crm/CRMConfigs', {
      params,
    });
  }

  getClientContactHistory() {
    const params = new HttpParams().set(
      'clientId',
      localStorage.getItem('LeadSpotClientId')
    );
    return this.http.get<Contact[]>(
      environment.API_BASE_URL + 'User/ClientContactHistory',
      { params }
    );
  }

  logUserActivity() {
    const params = new HttpParams().set(
      'clientId',
      localStorage.getItem('LeadSpotClientId')
    );
    return this.http.get<number>(
      environment.API_BASE_URL + 'User/LogUserActivity',
      { params }
    );
  }
  logOut() {
    return this.http.get<number>(environment.API_BASE_URL + 'User/Logout');
  }

  deleteStatus(id) {
    const params = new HttpParams().set('id', id);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Crm/deleteStatus',
      { params }
    );
  }

  renameCampaign(id, name) {
    const params = new HttpParams().set('id', id).set('name', name);
    return this.http.get<any>(
      environment.API_BASE_URL + 'ContactBook/RenameCampaign',
      { params }
    );
  }

  deleteTemplate(id) {
    const params = new HttpParams().set('id', id);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Crm/DeleteTemplate',
      { params }
    );
  }

  updateTemplate(id, title, msg) {
    const params = new HttpParams()
      .set('templateID', id)
      .set('title', title)
      .set('template', msg);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Crm/UpdateTemplate',
      { params }
    );
  }

  changeContactCampaign(contactLinkedInId, fromCampaignId, toCampaignId) {
    const params = new HttpParams()
      .set('contactLinkedInId', contactLinkedInId)
      .set('fromCampaignId', fromCampaignId)
      .set('toCampaignId', toCampaignId);
    return this.http.get<any>(
      environment.API_BASE_URL + 'ContactBook/ChangeContactCampaign',
      { params }
    );
  }

  deleteProduct(id) {
    const params = new HttpParams().set('id', id);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Crm/deleteProduct',
      { params }
    );
  }
  deleteTag(id) {
    const params = new HttpParams().set('id', id);
    return this.http.get<number>(environment.API_BASE_URL + 'Crm/deleteTag', {
      params,
    });
  }
  saveCompany(companyId) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('companyId', companyId);
    return this.http.get<number>(
      environment.API_BASE_URL + 'User/saveCompany',
      { params }
    );
  }
  deleteCompany(companyId) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('companyId', companyId);
    return this.http.get<number>(
      environment.API_BASE_URL + 'User/deleteCompany',
      { params }
    );
  }
  getClientCompanies() {
    const params = new HttpParams().set(
      'clientId',
      localStorage.getItem('LeadSpotClientId')
    );
    return this.http.get<any[]>(
      environment.API_BASE_URL + 'Search/getClientCompanies',
      { params }
    );
  }

  addCustomerTags(tagIds, customerId) {
    const params = new HttpParams()
      .set('tagsIds', tagIds)
      .set('customerId', customerId);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Crm/AddCustomerTags',
      { params }
    );
  }
  deleteCustomerTags(tagIds, customerId) {
    const params = new HttpParams()
      .set('tagsIds', tagIds)
      .set('customerId', customerId);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Crm/DeleteCustomerTags',
      { params }
    );
  }

  // deleteCampaign(id){
  //   let params = new HttpParams().set("id",id)
  //   return this.http.get<number>(environment.API_BASE_URL+"Crm/DeleteCampaign",{params:params})
  // }

  addCustumer(model, id) {
    const params = new HttpParams().set('model', model);
    const clientId = id ? id : localStorage.getItem('LeadSpotClientId');
    return this.http.post<any>(
      environment.API_BASE_URL + 'Crm/CreateCustomer?clientId=' + clientId,
      { params }
    );
  }

  updateActionDate(ids: string, date: string) {
    const clientId = localStorage.getItem('LeadSpotClientId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });

    return this.http.get(
      environment.BASE_URL1 + `Command=UpdateLeadsBulk&CustomerId=${clientId}&LeadList=${ids}&Date=${date}`,
      {}
    );
  }
  moveCustomer(to, id) {
    const params = new HttpParams()
      .set('from', localStorage.getItem('LeadSpotClientId'))
      .set('to', to)
      .set('id', id);
    return this.http.get<any>(environment.API_BASE_URL + 'Crm/MoveCustomer', {
      params,
    });
  }

  sendEmai(from, name, request) {
    const params = new HttpParams()
      .set('from', from)
      .set('name', name)
      .set('request', request);
    return this.http.get<any>(environment.API_BASE_URL + 'User/SendEmail', {
      params,
    });
  }

  sendEmail(name, email, phone, country) {
    // let BaseUrl =
    //   'http://test-leadspottingapi-env.eba-mreepfyi.us-east-2.elasticbeanstalk.com/Leadspot.jsp?';
    const BaseUrl =
      'http://leadspottingapi-env.eba-mreepfyi.us-east-2.elasticbeanstalk.com/Leadspot.jsp?';

    const params = new HttpParams()
      .set('Name', name)
      .set('Email', email)
      .set('Phone', phone)
      .set('Country', country);

    return this.http.get<any>(
      environment.API_BASE_URL + 'User/SendEmailSMSToInbound',
      { params }
    );
  }

  replaceBillingInfo(model) {
    const params = new HttpParams().set('model', model);
    return this.http.post<any>(
      environment.API_BASE_URL + 'User/ReplaceBillingInfo',
      { params }
    );
  }
  billingInfo() {
    const params = new HttpParams().set(
      'clientId',
      localStorage.getItem('LeadSpotClientId')
    );
    return this.http.get<any>(environment.API_BASE_URL + 'User/BillingInfo', {
      params,
    });
  }

  deleteCampaign(id) {
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(
      environment.API_BASE_URL + 'ContactBook/DeleteCampaign',
      { params }
    );
  }

  getClientCampaigns() {
    const params = new HttpParams().set(
      'clientId',
      localStorage.getItem('LeadSpotClientId')
    );
    return this.http.get<any>(environment.API_BASE_URL + 'ContactBook', {
      params,
    });
  }

  deleteBillingInfo() {
    const params = new HttpParams().set(
      'clientId',
      localStorage.getItem('LeadSpotClientId')
    );
    return this.http.get<any>(
      environment.API_BASE_URL + 'User/DeleteBillingInfo',
      { params }
    );
  }

  editCustumer(model) {
    const params = new HttpParams().set('model', model);
    return this.http.post<any>(
      environment.API_BASE_URL +
        'Crm/EditCustomer?clientId=' +
        localStorage.getItem('LeadSpotClientId'),
      { params }
    );
  }

  getCustomers(selectedUserId) {
    let params;
    if (selectedUserId == 0) {
      params = new HttpParams().set(
        'clientId',
        localStorage.getItem('LeadSpotClientId')
      );
    } else {
      params = new HttpParams().set('clientId', selectedUserId);
    }
    return this.http.get<CustomerMap>(
      environment.API_BASE_URL + 'Crm/getCustomers',
      { params }
    );
  }

  deleteCustomer(customerId) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('customerId', customerId);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Crm/deleteCustomer',
      { params }
    );
  }

  deleteProject(projectId) {
    const params = new HttpParams().set('projectId', projectId);
    return this.http.get<number>(
      environment.API_BASE_URL + 'Lead/deleteProject',
      { params }
    );
  }
  login(username, password) {
    const params = new HttpParams()
      .set('userName', username)
      .set('password', password);
    return this.http.get<User>(environment.API_BASE_URL + 'User/login', {
      params,
    });
  }
  resendVerficationEmail(token) {
    const params = new HttpParams().set('token', token);
    return this.http.get<string>(
      environment.API_BASE_URL + 'User/resendVerficationEmail',
      { params }
    );
  }
  forgotPasswordRequest(email: string) {
    const params = new HttpParams().append('email', email);
    return this.http.get<string>(environment.API_BASE_URL + 'User/forgotPass', {
      params,
    });
  }

  createNewContactBookList(name: string) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('name', name);
    return this.http.get<string>(
      environment.API_BASE_URL + 'ContactBook/newList',
      { params }
    );
  }
  copyListToClient(listId, industryId, locationId, count) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('listId', listId)
      .set('industryId', industryId)
      .set('locationId', locationId)
      .set('count', count);
    return this.http.get<string>(
      environment.API_BASE_URL + 'Search/copyListToClient',
      { params }
    );
  }
  resendVerfication(email: string) {
    const params = new HttpParams().append('email', email);
    return this.http.get<string>(
      environment.API_BASE_URL + 'User/resendVerfication',
      { params }
    );
  }

  resetPasswordRequest(token: string, newPass) {
    const params = new HttpParams()
      .append('token', token)
      .append('pass', md5(newPass));
    // .append('pass', newPass);
    return this.http.get<string>(`${environment.API_BASE_URL}User/Reset`, {
      params,
    });
  }
  getLeadProjects(selectedUserId) {
    let params;
    if (selectedUserId == 0) {
      params = new HttpParams().set(
        'clientId',
        localStorage.getItem('LeadSpotClientId')
      );
    } else {
      params = new HttpParams().set('clientId', selectedUserId);
    }
    return this.http.get<Project[]>(
      environment.API_BASE_URL + 'Lead/GetProjects',
      { params }
    );
  }
  cancelSubscription() {
    const params = new HttpParams().set(
      'clientId',
      localStorage.getItem('LeadSpotClientId')
    );
    return this.http.get<number>(
      environment.API_BASE_URL + 'User/CancelSubscription',
      { params }
    );
  }
  clientPlan(selectedUserId) {
    let params;
    if (selectedUserId == 0) {
      params = new HttpParams().set(
        'clientId',
        localStorage.getItem('LeadSpotClientId')
      );
    } else {
      params = new HttpParams().set('clientId', selectedUserId);
    }
    return this.http.get<PlanModel>(
      environment.API_BASE_URL + 'User/ClientPlan',
      { params }
    );
  }
  createLeadProject(model) {
    return this.http.post<number>(
      environment.API_BASE_URL + 'Lead/newLeadProject',
      model
    );
  }
  updateClientPlan(planId, subscriptionId, SubscriptionType) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('PlanId', planId)
      .set('SubscriptionId', subscriptionId)
      .set('SubscriptionType', SubscriptionType);
    return this.http.get<string>(
      environment.API_BASE_URL + 'User/updateClientPlan',
      { params }
    );
  }
  setIntroDone() {
    const params = new HttpParams().set(
      'clientId',
      localStorage.getItem('LeadSpotClientId')
    );
    return this.http.get<string>(
      environment.API_BASE_URL + 'User/SetIntroDone',
      { params }
    );
  }

  inviteUser(user) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('email', user);
    return this.http.get<number>(environment.API_BASE_URL + 'User/inviteUser', {
      params,
    });
  }
  createLeadDiscoveryTask(model) {
    return this.http.post<number>(
      environment.API_BASE_URL + 'Lead/CreateLeadDiscoveryTask',
      model
    );
  }

  deleteContactFromCampaign(linkedInId, campaignId) {
    const params = new HttpParams()
      .set('contactLinkedInId', linkedInId)
      .set('campaignId', campaignId);
    return this.http.get<any>(
      environment.API_BASE_URL + 'ContactBook/deleteContactFromCampaign',
      { params }
    );
  }

  getclientUsers(selectedUserId?) {
    let params;
    if (!selectedUserId) {
      params = new HttpParams().set(
        'clientId',
        localStorage.getItem('LeadSpotClientId')
      );
    } else {
      params = new HttpParams().set('clientId', selectedUserId);
    }
    return this.http.get<ClientUser[]>(
      environment.API_BASE_URL + 'User/clientUsers',
      { params }
    );
  }
  getpartnerClients() {
    const params = new HttpParams().set(
      'PartnerId',
      localStorage.getItem('LeadSpotClientId')
    );
    return this.http.get<PartnerClients[]>(
      environment.API_BASE_URL + 'User/partnerClient',
      { params }
    );
  }
  searchCompanyContact(
    comapnyId,
    positionId,
    NumberOfLeads,
    FetchTopManagement
  ) {
    const params = new HttpParams()
      .set('clientId', localStorage.getItem('LeadSpotClientId'))
      .set('companyId', comapnyId)
      .set('JobCategory', positionId)
      .set('NumberOfLeads', NumberOfLeads)
      .set('FetchTopManagement', FetchTopManagement);
    return this.http.get<CompanyContact[]>(
      environment.API_BASE_URL + 'Search/CompanyContactDiscovery',
      { params }
    );
  }

  getCrmStats(selectedUserId, model = null) {
    let params;
    if (selectedUserId == 0) {
      params = new HttpParams()
        .set('userId', localStorage.getItem('LeadSpotClientId'))
        .set('text', model?.text ? model.text : '')
        .set('campaignId', model?.campaignId ? model.campaignId : '')
        .set('productId', model?.productId ? model.productId : '')
        .set('industryId', model?.industryId ? model.industryId : '')
        .set('countryId', model?.countryId ? model.countryId : '')
        .set('tagId', model?.tagId ? model.tagId : '');
    } else {
      params = new HttpParams()
        .set('userId', selectedUserId)
        .set('text', model?.text ? model.text : '')
        .set('campaignId', model?.campaignId ? model.campaignId : '')
        .set('productId', model?.productId ? model.productId : '')
        .set('industryId', model?.industryId ? model.industryId : '')
        .set('countryId', model?.countryId ? model.countryId : '')
        .set('tagId', model?.tagId ? model.tagId : '');
    }
    return this.http.get<any>(environment.API_BASE_URL + 'Crm/CRMStats', {
      params,
    });
  }

  getIndustryPhrases() {
    return this.http.get<any>(
      environment.API_BASE_URL + 'Search/industyPhrases'
    );
  }
  companySearchStats(selectedUserId) {
    let params;
    if (selectedUserId == 0) {
      params = new HttpParams().set(
        'clientId',
        localStorage.getItem('LeadSpotClientId')
      );
    } else {
      params = new HttpParams().set('clientId', selectedUserId);
    }
    return this.http.get<any>(
      environment.API_BASE_URL + 'Crm/CompanySearchStats',
      { params }
    );
  }
  companiesCounts() {
    return this.http.get<string>(`${environment.API_BASE_URL}CompaniesCounts`);
  }

  getReadyLists(): Observable<any[]> {
    return this.http.get<any[]>(
      environment.API_BASE_URL + 'Search/getReadyLists'
    );
  }

  getImageTemplates() {
    return this.http.get<any>(
      environment.API_BASE_URL + 'User/getImageTemplates'
    );
  }

  getManualMessagesSent() {
    return this.http.get<any>(
      environment.API_BASE_URL + 'User/getImageTemplates'
    );
  }

  CreateEmailPersonalizationTask(model) {
    return this.http.post<any>(
      'http://leadspottingapi-env.eba-mreepfyi.us-east-2.elasticbeanstalk.com/Leadspot.jsp?Command=CreateEmailPersonalizationTask',
      model
    );
    // return this.http.post<any>(
    //   environment.API_BASE_URL + 'Lead/CreateEmailPersonalizationTask',
    //   model
    // );
  }

  PartnerProgramAutomation(email) {
    return this.http.get<any>(
      'http://leadspottingapi-env.eba-mreepfyi.us-east-2.elasticbeanstalk.com/Leadspot.jsp?Command=PartnerProgramAutomation&Email=' +
        email
    );
  }

  getReadyListsUpdated() {
    return this.http.get<any>(environment.API_BASE_URL + 'Lead/getReadyLists');
  }

  getReadyFullLists(countryId: string, industryId: string, limit: string) {
    const params = new HttpParams()
      .set('countryId', countryId)
      .set('industryId', industryId)
      .set('limit', limit);
    return this.http.get<any>(
      environment.API_BASE_URL + 'Lead/getReadyFullLists',
      { params }
    );
  }

  saveEvent(eventName) {
    const params = new HttpParams().set('EventName', eventName);
    return this.http.get<any>(environment.API_BASE_URL + 'User/SaveEvent', {
      params,
    });
  }

  getLinkedInUrl(ids: string) {
    const params = new HttpParams();
    return this.http.get(
      environment.BASE_URL1 + `Command=GetLeadsBulkLinkedInLink&CustomerId=${localStorage.getItem('LeadSpotClientId')}&LeadList=${ids}`,
      { params, responseType: 'text' }
    ).pipe(
      map((xmlResponse: string) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
        const lsResponse = xmlDoc.getElementsByTagName('LSResponse')[0].textContent;
        return lsResponse;
      })
    );
  }



  submitUninstallDetails(clientId: string, reasonType: string = null) {

    const url = 'https://api.leadspotting.com/Customers.jsp?';
    let parameters = new HttpParams().set('Command', 'unistallUserDetails')
      .set('ClientId', clientId);
    if (reasonType) {
      parameters = parameters.set('ReasonType', reasonType);
    }
    return this.http.get<any>(url, {
      params: parameters,
    });
  }
}
